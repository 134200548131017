.footer {
  margin-top: 6em;

  padding: 1em 12em;
  color: #979797;
  background-color: transparent;
}

.footer-line {
  width: 100%;
  height: .5px;
  background-color: #979797;
}

.footer-content {
  padding-top: .5em;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.footer p {
  cursor: default;
}

.footer a {
  color: #fff;
  text-decoration: none;
}

.footer a:hover {
  color: var(--main-color);
}

@media only screen and (max-width: 600px) {
  .footer {
    padding: 1em;
    margin-top: 2em;
  }

  .footer p, .footer a {
    font-size: 0.8em;
  }
}