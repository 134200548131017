.navbar {
  padding: 2em 0 0 0;
  position: fixed;

  top: 0;
  left: 50%;
  transform: translateX(-50%);

  z-index: 1001;
  overflow-x: hidden;
  transition: transform 0.3s ease-in-out;;
}

.navbar:hover .navbar-box {
  background: rgba(0, 0, 0, 0.1);
}

.navbar--show {
  transform: translate(-50%, 0);
}

.navbar--hide {
  transform: translate(-50%, -100%);
}

.navbar-box {
  background: var(--glass-background);
  border-radius: 12px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(80px);
  -webkit-backdrop-filter: blur(80px);
  border: 1px solid rgba(255, 255, 255, 0.05);
  
  padding: 1.2em;
  transition: background 0.3s ease;
}

.navbar ul {
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0;
}

.navbar ul li {
  margin: 0 1rem;
}

.navbar ul li a {
  color: var(--main-light-gray);
  text-decoration: none;
  font-weight: normal;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  transition: color 0.6s ease, font-weight 0.6s ease;
}

.navbar ul li a.active,
.navbar ul li a.active:hover {
  color: #fff;
  font-weight: 500;

  transition: color 0.6s ease;
}

.navbar ul li a:hover {
  text-shadow: 0 0 10px rgba(215, 215, 215, 0.3);
  transition: text-shadow 0.3s ease;
  font-weight: 500;
}