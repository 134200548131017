.pch {
    padding: 18px;

    background: var(--glass-background);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
    border: 1px solid rgba(255, 255, 255, 0.05);

    border-radius: 16px;

    transition: background 0.3s ease;

    text-decoration: none;
    color: #fff;

    display: flex;
    flex-direction: column;
    align-items: left;
    gap: 1em;
}

.pch:hover {
    background: rgba(0, 0, 0, 0.1);
}

.pch-imageContainer {
    display: inline-block;
    overflow: hidden;
    border-radius: 8px;
}

.pch:hover .pch-image {
    transform: scale(1.05);
    transform-origin: 50% 50%;
  }

.pch p {
    margin: 0;
    cursor: pointer;
}

.pch h3 {
    font-size: 1.4em;
    cursor: pointer;
}

.pch-image {
    width: 100%;
    border-radius: 8px;
    aspect-ratio: 2/1;

    object-fit: cover;

    transition: transform 0.6s ease;
}

.pch-tags {
    display: flex;
    align-items: center;
    gap: 1em;
}

.pch-tag {
    padding: .6em .8em;
    background-color: var(--main-color-transparent);
    color: var(--main-color);
    border-radius: 8px;
}

@media only screen and (max-width: 600px) {
    .pch-tags {
        justify-content: center;
    }
}