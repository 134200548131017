.pc {
    padding: 18px;

    background: var(--glass-background);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
    border: 1px solid rgba(255, 255, 255, 0.05);

    border-radius: 16px;

    transition: transform 0.3s ease, background 0.3s ease;

    text-decoration: none;
    color: #fff;

    display: flex;
    flex-direction: column;
    align-items: left;
    gap: .6em;

    flex: 1 1 250px;
    box-sizing: border-box;
}

.pc:hover {
    transform: scale(1.02);
    background: rgba(0, 0, 0, 0.5);
}

.pc p {
    margin: 0;
    cursor: pointer;
}

.pc h3 {
    font-size: 1.4em;
    cursor: pointer;
}

.pc-imageContainer {
    display: inline-block;
    overflow: hidden;
    border-radius: 8px;
}

.pc:hover .pc-image {
    transform: scale(1.05);
    transform-origin: 50% 50%;
  }

.pc-image {
    width: 100%;
    border-radius: 8px;
    aspect-ratio: 2/1;

    object-fit: cover;

    display: block;
  transition: transform .4s;
}

.pc-tags {
    display: flex;
    align-items: center;
    gap: 1em;
}

.pc-tag {
    padding: .6em .8em;
    background-color: var(--main-color-transparent);
    color: var(--main-color);
    border-radius: 8px;

    font-size: .8em;
    white-space: nowrap;
}

@media only screen and (max-width: 600px) {
    .pc-tags {
        justify-content: center;
    }
}