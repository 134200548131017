.projects {
  padding: 10em 12em 0 12em;
  background-repeat: no-repeat;
  min-height: 90vh;
}

.projects-content {
  position: relative;
  z-index: 1;
}

.projects-header {
  margin-bottom: 2em;
}

.projects-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
}

.projects-searchBar {
  width: 100%;
}

.projects h1,
.projects p,
.projects-searchBar,
.projects-list {
  opacity: 0;
  animation: fadeIn 1s ease forwards;
}

.projects h1 {
  animation-delay: 0.2s;
}

.projects p {
  animation-delay: 0.4s;
}

.projects-searchBar {
  animation-delay: 0.5s;
}

.projects-list {
  animation-delay: 0.2s;
}

@media only screen and (max-width: 1300px) {
  .projects {
    padding: 10em 6em 0 6em;
  }
}

@media only screen and (max-width: 580px) {
  .projects {
    padding: 10em 1em 0 1em;
  }
}