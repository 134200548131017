.legals-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
}

.legals {
    min-height: 85vh;
    margin-top: 15vh;
    margin-bottom: 4em;
    width: 60%;
    max-width: 800px;

    padding: 3.2em;
    border-radius: 12px;

    background: var(--glass-background);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
    border: 1px solid rgba(255, 255, 255, 0.05);
}

.legals h2 {
    font-size: 1.4em;
    font-weight: bold;
}