.banner {
  margin-top: 2em;
  display: flex;
  overflow-x: hidden;
  white-space: nowrap;
  position: relative;
  padding: 1em 0;
}

.banner-inner {
  display: flex;
  transition: transform 1s linear;
}

.banner-imageContainer {
  background: var(--glass-background);
  border-radius: 12px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(80px);
  -webkit-backdrop-filter: blur(80px);
  border: 1px solid rgba(255, 255, 255, 0.05);
  margin: 0 1.2em;
  padding-bottom: 10px;

  transition: background 0.3s ease;
}

.banner-imageContainer:hover {
  background: rgba(0, 0, 0, 0.5);
}

.banner-image {
  width: 300px;
  cursor: pointer;
  border-radius: 12px;
  transition: transform 0.3s ease;
}

.banner-image:hover {
  transform: scale(1.05);
}

.banner-tag-container {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  background-color: var(--background-color);
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 1rem;
  text-align: center;
}

.popup-tag-container {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  background-color: var(--background-color);
  color: white;
  padding: 12px 24px;
  border-radius: 5px;

  text-align: center;

  font-size: 1.6rem;
  font-weight: 500;
  margin-bottom: 2em;
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.95);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1003;

  backdrop-filter: blur(10px);
}

.popup-image {
  max-width: 80%;
  max-height: 80%;
  border-radius: 12px;
}

.banner-close-button {
  position: absolute;
  top: 20px;
  right: 20px;
  background: none;
  border: none;
  color: white;
  font-size: 2rem;
  cursor: pointer;
  padding: 2px;
  border-radius: 100px;

  display: flex;
  align-items: center;
  justify-content: center;
}

.banner-close-button:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.prev-button,
.next-button {
  position: absolute;
  top: 50%;
  background: none;
  border: none;
  color: white;
  font-size: 2rem;
  cursor: pointer;
  transform: translateY(-50%);
}

.prev-button {
  left: 20px;
}

.next-button {
  right: 20px;
}