
/* ---------------------------------------------------------------------- */
/* ANIMATIONS */

.home-intro-box,
.home-intro h1,
#title-1,
#title-2,
#title-3,
.home-intro-content,
.home-recentProjects
{
    opacity: 0;
    animation: fadeIn 1s ease forwards;
}

.home-intro-box {
    animation-delay: 0.2s;
}

.home-intro h1 {
    animation-delay: 0.2s;
}

#title-1 {
    animation-delay: 0.6s;
}

#title-2 {
    animation-delay: 0.8s;
}

#title-3 {
    animation-delay: 1s;
}

.home-intro-content {
    animation-delay: 1.2s;
}

.home-recentProjects {
    animation-delay: 0.8s;
}

/* ---------------------------------------------------------------------- */

.home {
    padding-top: 10em;
    
    background-size:     contain;                      /* <------ */
    background-repeat:   no-repeat;
    background-position: top center; 
}

.home section {
    text-align: left;
    padding: 0 12em;
    z-index: 1000;
    overflow-x: hidden;
}

.home-conceptDesigns {
    text-align: left;
    padding: 0 12em;
    z-index: 1000;
    overflow-x: hidden;
}

.home-mainTitle, .home-intro-content p {
    margin: 0;
}

.home-mainTitle {
    font-size: 3.2em;
}

.home-intro-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.6em;
}

.home-intro {
    position: relative;
    min-height: 50vh;

    display: flex;
    align-items: center;
    justify-content: center;
}

.home-intro-titles {
    display: flex;
    align-items: center;
    gap: 1.6em;
}

.home-intro-title {
    background: var(--glass-background);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
    border: 1px solid rgba(255, 255, 255, 0.05);

    padding: .8em 1.2em;
    font-size: 1.2em;
    border-radius: 12px;
    color: var(--main-color);
    font-weight: 500;

    white-space: nowrap;
}

.home-intro-content {
    background: var(--glass-background);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
    border: 1px solid rgba(255, 255, 255, 0.05);

    padding: 1.2em 1em;
    border-radius: 12px;
    font-weight: 500;

    text-align: center;
    width: 80%;
}

.home-intro h1 {
    margin-bottom: 0em;
}

.home-intro h2 {
    color: var(--main-color);
}

.home-intro p {
    font-size: 1.4em;
    font-weight: 500;
}

.home-convertButton {
    margin-top: 1em;
}

.home-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.home-recentProjects {
    position: relative;
    margin: 5em 0;
    overflow: visible;
}

.home-projectsList {
    padding: 3em 0 4em 0;

    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 24px;
}

.home-contactForm {
    margin-top: 10em;
}

.home-contactForm-wrapper {
    width: 100%;
}

.home-contactFormContent {
    margin-top: 2em;

    display: flex;
    align-items: flex-start;
    justify-content: first baseline;
    gap: 2.4em;
    height: 100%;
}

.home-contactLinks {
    padding-top: 2.4em;
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1em;
}

.home-contactLinks-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 2em;
    padding-top: 1em;
}

.home-socialIcons {
    display: flex;
    align-items: center;
    gap: 1em;
}

.home-contactInfo {
    display: flex;
    flex-direction: column;
    gap: 1em;

    width: 100%;
}

.iconBox {
    width: 3em;
    height: 3em;

    color: var(--ivory);
    font-size: 1.2em;

    background: var(--glass-background);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
    border: 1px solid rgba(255, 255, 255, 0.05);

    border-radius: 8px;

    display: flex;
    align-items: center;
    justify-content: center;

    transition: background 0.3s ease, transform 0.3s ease, color 0.3s ease;
}

.iconBox:hover {
    background: var(--main-color);
    color: var(--background-color);
    cursor: pointer;
    transform: scale(1.02);
}

/* .contactInfoBox {
    display: flex;
    align-items: center;
    gap: 1em;

    padding: 0 1.2em;
    height: 3em;
    border-radius: 8px;

    background: var(--glass-background);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
    border: 1px solid rgba(255, 255, 255, 0.05);

    font-size: 1.4em;
    color: var(--ivory);

    transition: background 0.3s ease, transform 0.3s ease, color 0.3s ease;
}

.contactInfoBox:hover {
    background: var(--main-color);
    color: var(--background-color);
    cursor: pointer;
    transform: scale(1.02);
}

.contactInfoBox p {
    font-size: 16px;
    white-space: nowrap;
    cursor: pointer;
} */

@media only screen and (max-width: 1100px) {
    .home section, .home-conceptDesigns {
        padding: 0 3em;
    }

    .home {
        background-size: auto;
    }

    .home-header {
        width: 100%;

        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .home-contactForm {
        position: relative;
        margin: 1em 0;

        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .home-contactFormContent {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    
    .home-contactLinks {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .home-contactLinks-content {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}

@media only screen and (max-width: 820px) {
    .home-projectsList {
        padding: 2em 0 0 0;
    
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 24px;
    }
}


@media only screen and (max-width: 600px) {

    .home section {
        padding: 0 1em;
    }

    .home-intro p {
        font-size: 1.2em;
    }

    .home-intro-title {
        font-size: 1.2em;
        
    }
    
    .home-intro-titles {
        gap: 1em;
        flex-wrap: wrap;
        justify-content: center;
        width: 100%;
    }

    .home-intro h1 {
        font-size: 2.4em;
    }

    .home-intro {
        padding: 0 1em;
    }
    
    .home-projectsList {
        padding: 2em 0 0 0;
    
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 24px;
    }

    .home-conceptDesigns {
        padding: 0 1em;
        margin-bottom: 4em;
    }

    .home-header {
        width: 100%;

        display: flex;
        align-items: center;
        justify-content: space-between;
    }
  }