.downloadPreviewBox {
    display: flex;
    flex-direction: column;
    align-items: center;

    position: relative;
}

.previewText {
    visibility: hidden;
    position: absolute;
    top: 10px;
    z-index: 1001;

    top: 50%;
    transform: translateY(-50%);

    display: flex;
    align-items: center;
    justify-content: center;
    gap: .3em;
}

.mini-image {
    width: 100%;
    cursor: pointer;
    border-radius: 12px;
    transition: transform 0.3s ease;

    display: flex;
    align-items: center;
    justify-content: center;
}

.downloadPreviewBox img {
    transition: filter 0.3s ease;
}

.downloadPreviewBox:hover img {
    filter: brightness(30%);
}

.downloadPreviewBox:hover .previewText {
    visibility: visible;
    cursor: pointer;
}

.downloadButton {
    position: absolute;
    bottom: 10px;
    z-index: 1001;

    text-decoration: none;
    color: white;
    padding: 1em;

    background-color: rgba(0, 0, 0, 0.6);
    border-radius: 12px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(80px);
    -webkit-backdrop-filter: blur(80px);
    border: 1px solid rgba(255, 255, 255, 0.05);

    display: flex;
    align-items: center;
    justify-content: center;
    gap: .6em;

    white-space: nowrap;
}

.downloadButton:hover {
    background-color: var(--main-color);
    color: var(--background-color);
    cursor: pointer;
}

.doc-close-button {
    position: absolute;
    top: 20px;
    right: 20px;
    background: none;
    border: none;
    color: white;
    font-size: 2rem;
    cursor: pointer;
    padding: 2px;
    border-radius: 100px;

    display: flex;
    align-items: center;
    justify-content: center;
}

.doc-close-button:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }

.popup-downloadButton {
    text-align: center;
    text-decoration: none;
    color: white;
    padding: 1em;

    font-size: 1em;
    font-weight: 500;
    padding: 1em;

    background: var(--glass-background);
    border-radius: 12px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(80px);
    -webkit-backdrop-filter: blur(80px);
    border: 1px solid rgba(255, 255, 255, 0.05);

    display: flex;
    align-items: center;
    justify-content: center;
    gap: .6em;
}

.popup-downloadButton:hover {
    background-color: var(--main-color);
    color: var(--background-color);
    cursor: pointer;
}

.popupDoc {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.95);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1003;
}

.popup-content {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1em;
}

.popup-image {
    max-width: 80%;
    border-radius: 12px;
}