.navButton {
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: var(--main-color-transparent);
  color: var(--main-color);

  padding: 1.2em 1.6em;
  border-radius: 8px;

  width: fit-content;

  transition: color 0.3s ease;
  transition: transform 0.3s ease !important;

  text-decoration: none;

  position: relative !important;
  z-index: 1002 !important;
}

.navButton:hover {
  background-color: var(--main-color);
  color: var(--background-color);
  cursor: pointer;
  transform: scale(1.03) !important;
}