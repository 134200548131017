.scrollToTopButton {
    position: fixed;
    bottom: 32px;
    right: 32px;
    cursor: pointer;

    background: var(--glass-background);
    border-radius: 64px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(80px);
    -webkit-backdrop-filter: blur(80px);
    border: 1px solid rgba(255, 255, 255, 0.05);

    color: var(--ivory);

    width: 3em;
    height: 3em;

    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: .1em;

    transition: background-color .3s ease;

    z-index: 1002;
}


.scrollToTopButton.show {
    opacity: 1;
    animation: fadeIn 0.6s ease forwards;
  }
  
  .scrollToTopButton.hide {
    opacity: 0;
    animation: fadeOut 0.6s ease forwards;
  }

.scrollToTopButton:hover {
    background-color: var(--main-color-transparent);
    color: var(--main-color);
}