.pp-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
}

.pp {
    min-height: 85vh;
    margin-top: 2em;
    margin-bottom: 2em;
    width: 60%;
    max-width: 800px;

    padding: 3.2em;
    border-radius: 12px;

    background: var(--glass-background);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
    border: 1px solid rgba(255, 255, 255, 0.05);
}

.pp-header-top {
    display: flex;
    align-items: center;
    gap: 1em;
}

.pp-demoLink {
    width: 2em;
    height: 2em;

    border-radius: 6px;

    display: flex;
    align-items: center;
    justify-content: center;

    color: var(--main-color);
    background-color: var(--main-color-transparent);

    transition: background-color 0.3s ease, color 0.3s ease;
}

.pp-demoLink:hover  {
    color: var(--background-color);
    background-color: var(--main-color);
}

.pp-demoLink .tooltip {
    background: rgba(0, 0, 0, 0.6);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
    border: 1px solid rgba(255, 255, 255, 0.05);

    visibility: hidden;
    width: fit-content;
    white-space: nowrap;
    
    text-align: center;
    color: var(--ivory);
    padding: 1em;
    border-radius: 8px;
  
    /* Position the tooltip text */
    position: absolute;
    z-index: 1002;
    margin-left: 12em;
  
    /* Fade in tooltip */
    opacity: 0;
    transition: opacity 0.3s;
  }

  .pp-demoLink:hover .tooltip {
    visibility: visible;
    opacity: 1;
  }

.pp h2 {
    font-size: 1.4em;
    font-weight: bold;
}

.pp h3 {}

.pp h4 {
    font-size: .8em;
    font-weight: bold;
    color: var(--main-light-gray);
    margin-bottom: 1em;
}

.pp-content {
    margin: 2em 0 2em 0;
    transition: height 0.5s ease;
    overflow: hidden;
}

.fade-in {
    opacity: 0;
    animation: fadeIn 0.5s ease forwards;
}

/* COLLAB SECTION */

.collaborator-list {
    display: flex;
    align-items: center;
    gap: 2.4em;
    margin-top: 1.6em;
    height: 3em;

    overflow: visible;
}

/* SECTION SELECTOR */
.sections {
    display: flex;
    align-items: center;
    gap: 1em;
}

.section-button {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;

    padding: 1em;
    border-radius: 8px;

    background: rgba(255, 255, 255, 0.1);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(7.1px);
    -webkit-backdrop-filter: blur(7.1px);
    border: 1px solid rgba(255, 255, 255, 0.05);

    transition: background-color 0.3s ease, outline 0.3s ease;
}

.section-button:hover {
    background-color: var(--main-color-transparent);
    color: var(--ivory);
}

.sections .active {
    color: var(--main-color);
    outline: 1px solid var(--main-color);
    background-color: var(--main-color-transparent);
}

.imgWrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    margin: 2em 0;
}

.reduceImgSize {
    width: 60% !important;
}

.mobileSS {
    width: 256px;
    border-radius: 8px;
}

.pp-img {
    width: 90%;
    border-radius: 8px;
}

.pp,
.pp h1,
.pp-header,
.pp-mainDesc,
.pp h4 .collaborator-list,
.sections,
.pp-content {
    opacity: 0;
    animation: fadeIn 1s ease forwards;
}

.pp {
    animation-delay: 0.2s;
}

.pp h1 {
    animation-delay: 0.6s;
}

.pp-header {
    animation-delay: 0.6s;
}

.pp-mainDesc {
    animation-delay: 0.8s;
}

.pp h4 {
    animation-delay: 1s;
}

.collaborator-list {
    animation-delay: 1s;
}

.sections {
    animation-delay: 1s;
}

.pp-content {
    animation-delay: 1.2s;
}

@media only screen and (max-width: 1100px) {
    .pp {
        max-width: 100%;
        width: 100%;
        margin-right: 2em;
        margin-left: 2em;
    }
}

@media only screen and (max-width: 600px) {
    .pp {
        max-width: 90%;
        width: 90%;
        margin-right: 1em;
        margin-left: 1em;
        padding: 1.2em;


    }

    .pp h1,
    .pp h2,
    .pp h3,
    .pp h4,
    .pp p {
        text-align: left;
    }

    .sections {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        gap: 1em;
    }
}